import React from "react";

export default function Locations(props) {
  return (
    <div className="location-card col-sm-6">
      <div className="location-name">
        <h2>
          <span className={props.currentLocation}>{props.nearMe}</span>
          {props.locationName}
        </h2>
      </div>
      <div className="temps">
        <div className="temp low">
          {props.tempLow}
          <span>&#176;</span>
        </div>
        <div className="temp high">
          {props.tempHigh}
          <span>&#176;</span>
        </div>
      </div>
      <div className="current">
        <div className="current-top">
          <div className="current-temp">
            {props.tempCurrent}
            <span>&#176;</span>
          </div>
          <div className="current-icon">
            <img src={props.icon} alt={props.description} />
          </div>
        </div>
        <div className="current-description">{props.description}</div>
      </div>
      <button
        className="delete btn btn-outline-dark"
        onClick={() => {
          props.delete(props.id);
        }}
      >
        <span className="material-symbols-outlined">delete</span>
      </button>
    </div>
  );
}
