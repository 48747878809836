import Background from "./Background";
import Content from "./Content";
import Footer from "./Footer";

function App() {
  return (
    <>
      <Background />
      <Content />
      <Footer />
    </>
  );
}

export default App;
