import React from "react";

export default function Background() {
  return (
    <div className="background">
      <div className="background-top">
        <h1 className="title">Simply Weather</h1>
      </div>
    </div>
  );
}
