import React, { useState, useEffect } from "react";

import Locations from "./Locations";

export default function Content() {
  // USE STATES
  const [coords, setCoords] = useState([]);
  const [inputText, setInputText] = useState("");
  const [locations, setLocations] = useState([]);

  function handleChange(event) {
    setInputText(event.target.value);
  }

  useEffect(() => {
    const coords = JSON.parse(localStorage.getItem("coords"));
    if (coords) {
      setCoords(coords);
      getWeatherFromCoords(coords);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("coords", JSON.stringify(coords));
  }, [coords]);
  // MANUAL SEARCH

  // handles search click and updated weather info object
  function handleSearchClick() {
    const APIKey = process.env.REACT_APP_API_KEY;
    const locationName = inputText;
    fetch(
      `https://api.openweathermap.org/data/2.5/weather?q=${locationName}&units=imperial&appid=${APIKey}`
    )
      .then(response => {
        return response.json();
      })
      .then(weather => {
        console.log(weather);
        const icon = weather.weather[0].icon;
        const idNumber = Date.now();
        setLocations(prevLocation => {
          return [
            {
              locationName: weather.name,
              tempLow: Math.round(weather.main.temp_min),
              tempHigh: Math.round(weather.main.temp_max),
              tempCurrent: Math.round(weather.main.temp),
              icon: `http://openweathermap.org/img/wn/${icon}@2x.png`,
              description: weather.weather[0].description,
              delete: deleteLocation,
              id: idNumber
            },
            ...prevLocation
          ];
        });
        // Get cords and add them to array
        const newCoords = {
          id: idNumber,
          lat: weather.coord.lat,
          lon: weather.coord.lon
        };
        setCoords(prevCoords => {
          return [...prevCoords, newCoords];
        });
        // addToLocations(coords[coords.length - 1]);
        console.log(coords);
        setInputText("");
      });
  }

  function getWeatherFromCoords(coords) {
    coords.map(eachCoords => {
      const APIKey = process.env.REACT_APP_API_KEY;
      return fetch(
        `https://api.openweathermap.org/data/2.5/weather?lat=${eachCoords.lat}&lon=${eachCoords.lon}&units=imperial&appid=${APIKey}`
      )
        .then(response => {
          return response.json();
        })
        .then(weather => {
          const icon = weather.weather[0].icon;
          const idNumber = eachCoords.id;
          setLocations(prevLocation => {
            return [
              {
                locationName: weather.name,
                tempLow: Math.round(weather.main.temp_min),
                tempHigh: Math.round(weather.main.temp_max),
                tempCurrent: Math.round(weather.main.temp),
                icon: `http://openweathermap.org/img/wn/${icon}@2x.png`,
                description: weather.weather[0].description,
                delete: deleteLocation,
                id: idNumber
              },
              ...prevLocation
            ];
          });
        });
    });
  }
  // Search Location
  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  // get current location lat and lon
  function showPosition(position) {
    const lat = position.coords.latitude;
    const lon = position.coords.longitude;
    const APIKey = process.env.REACT_APP_API_KEY;
    return fetch(
      `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=imperial&appid=${APIKey}`
    )
      .then(response => {
        return response.json();
      })
      .then(weather => {
        const icon = weather.weather[0].icon;
        const idNumber = Date.now();
        setLocations(prevLocation => {
          return [
            {
              currentLocation: "material-symbols-outlined",
              nearMe: "near_me",
              locationName: weather.name,
              tempLow: Math.round(weather.main.temp_min),
              tempHigh: Math.round(weather.main.temp_max),
              tempCurrent: Math.round(weather.main.temp),
              icon: `http://openweathermap.org/img/wn/${icon}@2x.png`,
              description: weather.weather[0].description,
              delete: deleteLocation,
              id: idNumber
            },
            ...prevLocation
          ];
        });
      });
  }

  // Delete Location
  function deleteLocation(id) {
    setLocations(prevLocation => {
      return prevLocation.filter(item => {
        return item.id !== id;
      });
    });
    setCoords(prevCoords => {
      return prevCoords.filter(item => {
        return item.id !== id;
      });
    });
  }

  // Page Content
  return (
    <div className="content">
      <div className="content-top">
        <div className="mb-3 location-search">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Search Location:
          </label>
          <input
            onChange={handleChange}
            type="text"
            autoComplete="off"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Ex. Orlando, Florida"
            value={inputText}
          />
        </div>
        <button
          type="button"
          onClick={handleSearchClick}
          className="btn btn-primary btn-search"
        >
          SEARCH
        </button>
        <button
          type="button"
          onClick={handleLocationClick}
          className="btn btn-outline-primary btn-location"
        >
          <span className="material-symbols-outlined">my_location</span>
        </button>
      </div>
      <div className="locations container-fluid">
        <div className="row">
          {locations.map((eachLocation, index) => (
            <Locations
              key={index}
              currentLocation={eachLocation.currentLocation}
              nearMe={eachLocation.nearMe}
              locationName={eachLocation.locationName}
              tempLow={eachLocation.tempLow}
              tempHigh={eachLocation.tempHigh}
              tempCurrent={eachLocation.tempCurrent}
              icon={eachLocation.icon}
              description={eachLocation.description}
              id={eachLocation.id}
              delete={deleteLocation}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
